
import { defineComponent, onMounted, reactive, Ref, ref, toRefs } from 'vue'
import { STATE } from '../../consts/index'
import { foreignExchangeRecords, findDictOpenAccount } from '../../gql/index'
// import dmzClient from '../../apollo-client/dmz'
import apolloClient from '../../apollo-client/index'
import {
  ExchangeRecord,
  FilterInput,
  OptionType,
  StateInterface
} from '../../consts/interface'
import { formatNumber } from '@/utils/index'
import * as storage from '@/utils/storage'

export default defineComponent({
  name: 'Home',
  components: {},
  setup () {
    const { clientId, tradeAccount }: any = storage.get('userInfo')
    const state: StateInterface = reactive({
      showDirection: false,
      showState: false,
      currentDirection: '全部兑换方向',
      currentDirectionVal: '',
      currentState: '全部状态',
      currentStateVal: '',
      list: [],
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 20,
      total: 0,
      pages: 0,
      searchLoading: true
    })

    const directionActions: any = ref([{ name: '全部兑换方向', value: '' }])
    const stateActions: any = ref([{ name: '全部状态', value: null }])
    const moneyType = ref([])
    const params: Ref<FilterInput> = ref({
      exchangeOri: '',
      applicationStatusApp: null,
      size: state.pageSize,
      current: state.pageNo,
      tradeAccount: tradeAccount || clientId
    })

    const compare = (property: string) => {
      return function (a: { [x: string]: string }, b: { [x: string]: string }) {
        var value1 = new Date(a[property]).getTime()
        var value2 = new Date(b[property]).getTime()
        return value2 - value1 // 降序
      }
    }
    const getRecordList = (filter: FilterInput) => {
      apolloClient
        .query({
          query: foreignExchangeRecords,
          variables: {
            filter
          },
          fetchPolicy: 'no-cache'
        })
        .then(res => {
          if (res.data.foreignExchangeRecords.data) {
            const list = res.data?.foreignExchangeRecords?.data?.records?.map(
              (item: ExchangeRecord) => {
                return {
                  ...item,
                  exchangeOri: directionActions.value.filter(
                    (ele: { value: string }) => ele.value === item.exchangeOri
                  )[0].name,
                  status: stateActions.value.filter(
                    (ele: { value: string }) =>
                      Number(ele.value) === Number(item.applicationStatus)
                  )[0].name
                }
              }
            )
            state.list = [...list, ...state.list]?.sort(compare('createTime'))
            console.log(state.list, 'list')
            state.total = res.data.foreignExchangeRecords.data?.total
            state.pages = res.data.foreignExchangeRecords.data?.pages
          }
          state.searchLoading = false
        })
        .catch(err => {
          console.log(err)
          state.searchLoading = false
        })
    }
    // 选择状态
    const onStateSelect = (item: OptionType) => {
      // const { clientId, tradeAccount }: any = storage.get('userInfo')
      state.showState = false
      if (item.value === state.currentStateVal) return
      state.currentState = item.name
      state.currentStateVal = item.value
      state.pageNo = 1
      state.list = []
      state.searchLoading = true
      params.value = {
        exchangeOri: state.currentDirectionVal,
        applicationStatusApp: state.currentStateVal
          ? Number(state.currentStateVal)
          : null,
        size: state.pageSize,
        current: state.pageNo,
        tradeAccount: tradeAccount || clientId
      }
      getRecordList(params.value)
    }
    // 选择兑换方向
    const onDirectionSelect = (item: OptionType) => {
      state.showDirection = false
      if (item.value === state.currentDirectionVal) return
      state.currentDirection = item.name
      state.currentDirectionVal = item.value
      state.pageNo = 1
      state.list = []
      state.searchLoading = true
      params.value = {
        exchangeOri: state.currentDirectionVal,
        applicationStatusApp: state.currentStateVal
          ? Number(state.currentStateVal)
          : null,
        size: state.pageSize,
        current: state.pageNo,
        tradeAccount: tradeAccount || clientId
      }
      getRecordList(params.value)
    }

    const onLoad = () => {
      setTimeout(() => {
        if (state.pageNo < state.pages) {
          state.pageNo++
          params.value = {
            exchangeOri: state.currentDirectionVal,
            applicationStatusApp: state.currentStateVal
              ? Number(state.currentStateVal)
              : null,
            size: state.pageSize,
            current: state.pageNo,
            tradeAccount: tradeAccount || clientId
          }
          getRecordList(params.value)
        } else {
          state.finished = true
        }
        // 加载状态结束
        state.loading = false

        // 数据全部加载完成
        if (state.list.length >= state.total && state.total !== 0) {
          state.finished = true
        }
      }, 1000)
    }
    const filterColor = (item: ExchangeRecord) => {
      switch (Number(item.applicationStatus)) {
        case STATE.STATE_1:
          return '#ff7127'
        case STATE.STATE_2:
          return '#FF414E'
        case STATE.STATE_3:
          return '#fff'
        default:
          break
      }
    }
    // 查询数据字典
    const findDict = async () => {
      await apolloClient
        .query({
          query: findDictOpenAccount,
          variables: {
            params: {
              markList: [
                'FOREIGN_EXCHANGE_ORI',
                'AO_FOREGN_EXCHANGE_APP_STATUS',
                'SEC_MONEY_TYPE'
              ]
            }
          }
        })
        .then(res => {
          const dict = res.data.findDictOpenAccount.data
          directionActions.value = [
            ...directionActions.value,
            ...dict.FOREIGN_EXCHANGE_ORI
          ]
          stateActions.value = [
            ...stateActions.value,
            ...dict.AO_FOREGN_EXCHANGE_APP_STATUS
          ]
          moneyType.value = dict.SEC_MONEY_TYPE
        })
    }
    onMounted(() => {
      findDict().then(() => {
        getRecordList(params.value)
      })
    })
    const filterMoneyType = (value: string) => {
      return moneyType.value.filter(
        (item: { name: string; value: string }) => item.value === value
      )[0]
    }
    return {
      ...toRefs(state),
      directionActions,
      stateActions,
      moneyType,
      onStateSelect,
      onDirectionSelect,
      onLoad,
      STATE,
      filterColor,
      filterMoneyType,
      formatNumber
    }
  }
})
