import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Operation } from '@apollo/client/core'
import * as crypoto from '@/utils/crypto'
import * as storage from '@/utils/storage'

dayjs.extend(utc)

interface Sign {
  'gh-nonce': string
  'gh-deviceid': string
  'gh-date': string
  'gh-token': string
  'gh-bodyhash': string
  'gh-appid': string
  'gh-sign': string
}

/**
 * 普通接口签名
 * @param operation
 * @returns
 */
export default function signWrap (operation?: Operation): Sign {
  let paramsBody: any = {}
  if (operation) {
    const { query: queryOper, operationName, variables } = operation
    const queryBody = queryOper.loc?.source.body
    paramsBody = {
      operationName,
      query: queryBody,
      variables
    }
  }
  // const deviceIdIo = storage.get('deviceId') || ''
  const { sessionId: token, deviceId: deviceid }: any = storage.get('userInfo') || {}
  // const sessionId = 'c7f8ca7609d4434b803bed241ab85810'
  // const token = sessionId
  const nonce = nanoid()
  // const deviceid = deviceIdIo
  const date = dayjs
    .utc()
    .format()
    .replace(/(-|:)/g, '')
  const bodyHash = crypoto
    .sha256Encrypt(JSON.stringify(paramsBody))
    .toUpperCase()
  const appid = process.env.VUE_APP_H5_APPID || ''

  const signStr = `GH-DEVICEID:${deviceid},GH-TOKEN:${token},GH-DATE:${date},GH-NONCE:${nonce},GH-BODYHASH:${bodyHash},GH-APPID:${appid}`
  const signName = crypoto.hmacSha256Encrypt(signStr).toUpperCase()

  return {
    'gh-nonce': nonce,
    'gh-deviceid': deviceid,
    'gh-date': date,
    'gh-token': token,
    'gh-bodyhash': bodyHash,
    'gh-appid': appid,
    'gh-sign': signName
  }
}
