import { createApp, provide, h } from 'vue'
import { ApolloClients } from '@vue/apollo-composable'
import * as Sentry from '@sentry/vue'
import apolloClient from './apollo-client'
import dmzClient from './apollo-client/dmz'
import App from './App.vue'
import router from './router'
import store from './store'
import { ActionSheet, Cell, List } from 'vant'
import './assets/scss/index.scss'
import './router/permission'

const app = createApp({
  setup () {
    provide(ApolloClients, {
      default: apolloClient,
      dmz: dmzClient
    })
  },
  render: () => h(App)
})

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

app
  .use(store)
  .use(router)
  .use(ActionSheet)
  .use(Cell)
  .use(List)
  .mount('#app')
