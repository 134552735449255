import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import home from '../views/home/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: home
  }
]

const router = createRouter({
  history: process.env.NODE_ENV === 'dev' ? createWebHashHistory() : createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
