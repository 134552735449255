export const STATES: { [propName: string]: string } = {
  3: '已兑换',
  2: '已驳回',
  1: '处理中'
}
export enum STATE {
  STATE_1 = 1,
  STATE_2,
  STATE_3
}

export const EXCHANGEORI: { [propName: string]: string } = {
  'USD/HKD': '美元换港元',
  'HKD/USD': '港元换美元',
  'USD/CNH': '美元换人民币',
  'CNH/USD': '人民币换美元',
  'HKD/CNH': '港元换人民币',
  'CNH/HKD': '人民币换港元'
}
