// export function formatNumber (num: number | string): string {
//   const parts = num.toString().split('.')
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//   return parts.join('.')
// }
export function formatNumber (num: number, n = 2): string {
  if (!num || num === 0) return '0'
  let filnum = null
  let res = ''
  if (+n !== 0) {
    filnum = (+num).toFixed(n + 1)
    res = filnum
      .toString()
      .substring(0, filnum.lastIndexOf('.') + n + 1)
      .replace(/\d+/, function (n) {
        // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
          return $1 + ','
        })
      })
  } else {
    filnum = num.toString().split('.')[0]
    res = filnum.toString().replace(/\d+/, function (n) {
      // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
        return $1 + ','
      })
    })
  }
  return res
}
