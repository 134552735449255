import { gql } from 'graphql-tag'

export const foreignExchangeRecords = gql`
  query foreignExchangeRecords($filter: FilterForeignExchangeRecordsInput!) {
    foreignExchangeRecords(filter: $filter) {
      ok
      data {
        records {
          exchangeOri
          outCurrencyType
          inCurrencyType
          exchangeOutMoney
          exchangeInMoney
          applicationStatus
          reasonDesc
          createTime
        }
        total
        size
        current
        optimizeCountSql
        hitCount
        countId
        maxLimit
        searchCount
        pages
      }
      reason {
        err
        desc
      }
    }
  }
`

// 查询数据字典
export const findDictOpenAccount = gql`
  query findDictOpenAccount($params: FindDictInput) {
    findDictOpenAccount(param: $params) {
      data
      ok
      reason {
        err
        desc
      }
    }
  }
`
